.overlay--loader {
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(255, 255, 255, 0.35);

	.loader {
		display: block;
		position: relative;
		left: 50%;
		top: 50%;
		width: 100px;
		height: 100px;
		margin: -75px 0 0 -75px;
		border-radius: 50%;
		border: 3px solid transparent;
		border-top-color: #0e131a56;
		-webkit-animation: spin 1.7s linear infinite;
		animation: spin 1.7s linear infinite;

		// &::before {
		// 	content: "";
		// 	position: absolute;
		// 	top: 5px;
		// 	left: 5px;
		// 	right: 5px;
		// 	bottom: 5px;
		// 	border-radius: 50%;
		// 	border: 3px solid transparent;
		// 	border-top-color: #0e131a56;
		// 	-webkit-animation: spin-reverse 0.6s linear infinite;
		// 	animation: spin-reverse 0.6s linear infinite;
		// }

		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	top: 15px;
		// 	left: 15px;
		// 	right: 15px;
		// 	bottom: 15px;
		// 	border-radius: 50%;
		// 	border: 3px solid transparent;
		// 	border-top-color: #0e131a56;
		// 	-webkit-animation: spin 1s linear infinite;
		// 	animation: spin 1s linear infinite;
		// }
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin-reverse {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
.spinner-loader {
	animation: rotate 1s linear infinite;
	z-index: 9999;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
	width: 50px;
	height: 50px;
	
	& .path {
	  stroke: rgb(180, 182, 183);
	  stroke-linecap: round;
	  animation: dash 1s ease-in-out infinite;
	}
	
  }
  
  @keyframes rotate {
	100% {
	  transform: rotate(360deg);
	}
  }
  
  @keyframes dash {
	0% {
	  stroke-dasharray: 1, 150;
	  stroke-dashoffset: 0;
	}
	50% {
	  stroke-dasharray: 90, 150;
	  stroke-dashoffset: -35;
	}
	100% {
	  stroke-dasharray: 90, 150;
	  stroke-dashoffset: -124;
	}
  }
