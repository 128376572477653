.fc-theme-standard th.fc-day-today {
    color: black;
    border-bottom: 3px solid red;
}
.arrowLeft {
    top: 1.5rem;
    height: 10px;
    position: absolute;
    width: 0;
    border: 10px solid transparent;
    border-top-color: black;
    cursor: pointer;
    transform: rotate(90deg);
}
.fc .fc-timegrid-now-indicator-line {
    /* border-color: red !important; */
}
.fc .fc-timegrid-now-indicator-arrow {
    border-color: #092c54 !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
}
.arrowRight {
    top: 1.5rem;
    height: 10px;
    position: absolute;
    width: 0;
    border: 10px solid transparent;
    border-top-color: black;
    cursor: pointer;
    right: 0;
    transform: rotate(270deg);
}
@media screen and (min-width: 200px) and (max-width: 600px) {
    .arrowLeft,
    .arrowRight {
        border: 7px solid transparent;
        border-top-color: black;
    }
}
@media screen and (min-width: 400px) and (max-width: 500px) {
    .providerName {
        font-size: 0.8rem;
    }
}
@media screen and (min-width: 200px) and (max-width: 399px) {
    .providerName {
        font-size: 0.6rem;
    }
}
.block_bg {
    background-color: rgb(172, 172, 172);
}
.fullLine {
    overflow: visible !important;
}
.fullLionWidth {
    left: -10000px !important;
    right: -10000px !important;
}
