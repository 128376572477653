/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Description: Global css style used in app.
 */

@import '../fonts/font-awesome/css/all.css';

@import './symbol.css';
@import './card.css';
@import './apexCharts.css';
@import './table.css';
@import './serviceColors.css';
@import './tabs.css';
@import './modal.css';
@import './label.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
html,
body {
    font-size: 14px;
    padding: 0;
    margin: 0;
    font-family: 'Proxima Nova', sans-serif;
    height: 100%;
    width: 100%;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0px !important;
}
body {
    background-color: #edf2f7;
    font-size: 14.5px;
    color: #263446;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

#root {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.layout {
    width: 100%;
    height: 100%;
    overflow: auto;
}

a {
    color: inherit !important;
    text-decoration: none !important;
}

* {
    box-sizing: border-box;
}

.layout-no--padding {
    margin: -2rem;
}

.layout-wrapper {
    margin-left: 260px;
}

.btn {
    padding: 13px 26px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 8px;
    font-size: 13px;
}

.btn.btn-md {
    padding: 10px 22px;
}

.btn.btn-icon {
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn.btn-icon i {
    font-size: 1.2rem;
}

.btn.disabled {
    background-color: #d6d6d7;
    color: #fff;
    border: 1 px solid #ebebeb;
    cursor: not-allowed;
}

.btn.btn-secondary {
    color: #3f4254;
    border-color: #e4e6ef;
    background-color: #e4e6ef;
}

.btn.btn-border {
    @apply border text-black-500;

    background-color: #fff;
}

.activeNavBar {
    color: #f4ffff;
}

.active {
    color: #8b8b8b;
}

.activeTab {
    background: rgb(189 189 189 / 30%);
    color: #f4ffff;
    border-radius: 5px;
}

.activeIcon {
    color: #fff;
}

.activeIconColor {
    color: #fff;
}

.isActivelist {
    background-color: #f1f1f1;
}

.isActiveDay {
    background-color: rgb(57, 57, 185);
    color: white;
}

/* The container <div> - needed to position the dropdown content */
.dropdown-nav {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-nav {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 0.42rem;
}

/* Links inside the dropdown */
.dropdown-content-nav a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content-nav a:hover {
    background-color: #e4e6ef;
    color: black !important;
    border-radius: 0.42rem;
}

/* Show the dropdown menu on hover */
.dropdown-nav:hover .dropdown-content-nav {
    display: block;
}

.activeDropDown {
    background-color: #092c4c;
    color: white !important;
}

.user-nav-dropdown {
    right: auto !important;
}

@media screen and (max-width: 985px) {
    .dropdown-nav:hover .dropdown-content-nav {
        display: none !important;
    }
    .dropdown-nav {
        display: block;
    }
}
.activeLinkCutom {
    color: white;
    background: rgb(189 189 189 / 30%);
    border-radius: 4px;
}
button:active {
    border: none !important;
    outline: none !important;
}
button:focus {
    border: none !important;
    outline: none !important;
}
.mdb-datatable table tr {
    cursor: pointer !important;
}
.heading {
    text-align: center;
    align-self: flex-start;
    margin-bottom: 0;
}

#videos {
    position: relative;
    height: 85vh;
    width: 100vw;
    margin: auto;
    align-self: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    justify-items: center;
    align-items: center;
}

.vid {
    position: relative;
    background-color: black;
    height: 95%;
    width: 95%;
    border-width: 1px;
    border-color: #38373a;
    border-style: solid;
}

.controls {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    margin: -20px auto;
    display: grid;
    grid-template-columns: repeat(3, 33%);
    align-items: center;
    justify-items: center;
    z-index: 1;
    width: 500px;
    max-width: 60vw;
}

.controls p {
    padding: 10px;
    cursor: pointer;
    background: #38373a;
    color: #f7f7f7;
    border-width: 1px;
    border-color: #f7f7f7;
    border-style: solid;
}

.controls p.on {
    background: #f7f7f7;
    color: #38373a;
    border-width: 1px;
    border-color: #38373a;
    border-style: solid;
}

.join {
    position: absolute;
    z-index: 1;
    width: 30vw;
    height: fit-content;
    height: -moz-max-content;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 75vw;
}

.join input {
    padding: 15px;
    font-size: 1rem;
    border-width: 1px;
    border-color: #38373a;
    border-style: solid;
    width: 80%;
    display: block;
    margin: 50px auto;
}

.join button {
    min-width: 200px;
    padding: 12px 0;
    text-align: center;
    background-color: #38373a;
    color: #f7f7f7;
    border-width: 1px;
    border-color: #f7f7f7;
    border-style: solid;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    display: block;
    margin: 0 auto;
}
.maincontaine {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    align-items: center;
    height: 90vh;
}
.modal-content {
    max-height: 48rem;
}

.video-modal-content {
    max-width: 28rem !important;

}
.video-modal-body {
    background-color: #f2f8ff;
    overflow: hidden;
    overflow-y: scroll;
}
.video-modal-dialog-normal {
    min-width: 100%;
    max-width: 28rem;
    display: flex;
    align-items: center;
}
.control-btn {
    width: 2.4rem;
    height: 2.4rem;
}
.mediaPlayer > div {
    height: 15.6rem !important;
}

@media screen and (min-width: 992px) {
    .mediaPlayer > div {
        height: 40rem !important;
    }
}

.media-container {
    min-width: 16rem;
    height: 18rem;
    background-color: black;
}

.rotate {
    cursor: pointer;
    transform: rotate(-132deg) !important;
}

.rotate-90 {
    transform: rotate(90deg);
}

.hide-on-small {
    display: flex;
}

@media screen and (min-width: 120px) and (max-width: 360px) {
    .mediaPlayer {
        height: 100% !important;
    }

    .hide-on-small {
        display: none !important;
    }
}

.smallPlayer {
    width: 100px !important;
    max-height: 120px !important;
    position: relative;
    top: -8.6rem;
    right: -1%;
}

.scrolling-area {
    height: 48rem !important;
}

.video-screen-icon {
    width: 2.4rem;
    height: 2.4rem;
}

.video-modal-header {
    padding: 0.4rem 1rem !important;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
}

.video-modal-dialog-normal {
    min-width: 100%;
    max-width: 28rem;
    display: flex;
    align-items: center;
}

.video-modal-dialog-full-screen {
    min-width: 86rem;
    display: flex;
    align-items: flex-start;
}

.video-modal-dialog-small {
    max-width: 20rem;
    max-height: 20rem;
    display: flex;
    align-items: flex-start;
}

.media-container-medium {
    height: 18rem;
    width: 100%;
    background-color: black;
    border: none;
}
.video-container-top,
.video-container-bottom {
    height: 2.4rem;
    background-color: #949dab;
    z-index: 1;
}
.today_calendar {
    border: 2px solid #092c4c !important;
    border-top: none !important;
    border-bottom: none !important;
}

/* The container <div> - needed to position the dropup content */
.appointment-popup {
    position: relative;
    display: inline-block;
}

/* Dropup content (Hidden by Default) */
.appointment-popup-content {
    display: block;
    position: absolute;
    bottom: 50px;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 102;
}

/* Show the dropup menu on hover */
.dropup:hover .appointment-popup-content {
    display: block;
}
.custom-tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.custom-tooltip .tooltiptext {
    visibility: hidden;
    background: linear-gradient(350.99deg, #333333 -62.86%, #092c4c 125.32%);
    color: #fff;
    text-align: center;
    padding: 0.5rem;
    border-radius: 6px;
    width: 13.6rem;
    bottom: 90%;
    left: 0%;
    position: absolute !important;
    z-index: 10001 !important;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:hover .tooltiptext {
    visibility: visible;
}
.custom-tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.custom-tooltip .bottomToltip {
    visibility: hidden;
    background: linear-gradient(350.99deg, #333333 -62.86%, #092c4c 125.32%);
    color: #fff;
    text-align: center;
    padding: 0.5rem;
    border-radius: 6px;
    width: 13.6rem;
    top: 100%;
    left: 0%;
    position: absolute !important;
    z-index: 10001 !important;
}

.custom-tooltip:hover .bottomToltip {
  visibility: visible;
}
.custom-tooltip .bottomToltip::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.fc .fc-bg-event {
  opacity: 0.6 !important;
}

.hide {
    display: none;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  .eventBg:hover .hide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .slots_event:hover {
    background-color: white !important;
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgb(0 0 0 / 25%);
    justify-content: center;
    align-items: center;
    width: 100%;

  }
  .slots_event i{
    cursor: pointer !important;
  }

  .hover-overlay:hover {
    background-color: #6cdcff;
  }
  .tooltip{
    width: 13.6rem !important;
    background: linear-gradient(350.99deg, #333333 -62.86%, #092c4c 125.32%);
  }
  .tooltip-inner{
    background: linear-gradient(350.99deg, #333333 -62.86%, #092c4c 125.32%);
  }
  .tooltip-inner div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
  }