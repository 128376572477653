/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ApexChart Component Styles
 * Description: Styled components for ApexChart component tooltips.
 *
 */

.apexcharts-tooltip {
    background: #fff !important;
    color: #263446;
    border: 0 !important;
    box-shadow: none !important;
}
.apexcharts-tooltip-title {
    background: #fff !important;
}
