.appointment_statusLabel {
    padding: 2px 5px 2px 5px;
    display: inline-block;
    border-radius: 50px;
    font-size: 8.5px;
    font-weight: 400;
    color: #fff;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.appointment_statusSuccess {
    color: #00be70;
    background-color: #e2fff3;
}

.appointment_statusWarning {
    color: #ffc700;
    background-color: #fff8dd;
}

.appointment_statusDanger {
    color: #f1416c;
    background-color: #fff5f8;
}

.appointment_statusGray {
    color: #181c32;
    background-color: #eff2f5;
}
