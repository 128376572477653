/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Description: Managing symbol style for dropdowns.
 */

.symbol {
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
}
.symbol .symbol-label {
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 17px;
}
.symbol.symbol-circle .symbol-label {
    border-radius: 50%;
}
.symbol.symbol-primary .symbol-label {
    background-color: #d1e7ff;
    color: #419bf8;
}
.symbol.symbol-danger .symbol-label {
    background-color: #ffd7de;
    color: #f56e84;
}
.symbol .symbol-icon {
    font-size: 1.5rem;
}
