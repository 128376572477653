.edit_formContent {
    height: 100%;
    display: flex;

    .edit_leftColumn {
        padding: 24px;
        border-right: 1px solid #e5e7eb;
        flex-basis: 390px;
    }
    .edit_rightColumn {
        flex: 1;
    }
}
.service_colorPicker {
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 10%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        font-size: 1.125rem;
        color: #fff;
    }
}
.colorPicker_modalBody {
    min-width: 640px;
}
.colorPicker_serviceColor {
    width: 60px;
    height: 60px;
    border-radius: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.colorPicker_tickIcon {
    color: #fff;
    font-size: 40px;
}
.component_overlay {
    background-color: #f4f5f6;
    position: absolute;
    top: 64px;
    left: 0;
    width: calc(100%);
    height: 100%;
    overflow: hidden;
    padding: 2rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}
